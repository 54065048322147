.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* 半透明の背景色 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* 他の要素より手前に表示するためのz-index */
  }
  
  .overlay img {
    width: 100%;
    height: auto;
  }