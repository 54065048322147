/* style.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1300px;
  min-width: 630px;
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #007bff;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 32px;
  color: #333;
  margin-top: 5px;
  margin-bottom: 5px;
}

.image-select {
  width: 600px;
  min-height: 600px;
  display: block;
  margin-bottom: 20px;
}

.image-select img {
  width: 600px;
}

/* ファイル選択ボタンのスタイル */
.browse_btn {
  display: inline-block;
  background-color: #d3d3d3;
  padding: 6px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #999; /* 枠線を追加 */
}

/* ホバー時のスタイル */
.browse_btn:hover {
  background-color: #c0c0c0;
}

.browse_btn input[type='file'] {
  display: none;
}

/* 選択したファイル名の表示スタイル */
.image-select span {
  display: block;
  margin-top: 10px;
}

/* クイズ一覧フィールドのスタイル */
.quiz-list {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 20px;
  margin-bottom: 10px;
}

.quiz-header .col {
  background-color: ghostwhite;
  border: 2px solid #ccc;
  margin: -1px
}

.quiz-item .col {
  border: 2px solid #ccc;
  margin: -1px;
  padding: 2px;
}

/* フセンのスタイル */
.rectangle {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border: 1px solid #000000;
  text-align: center;
  line-height: 100px;
  font-size: 14px;
}

.rectangle-create {
  pointer-events: none;
}

.index-text {
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 18px;
  color: black;
  font-size: 20px;
}

.footer-row {
  padding: 2px;
}

.footer-row .col {
  padding: 2px;
}

.footer-row input {
  padding: 2px;
}

.footer-row button {
  padding: 2px;
  width: 90%;
}

.quiz-name-input {
  font-size: small;
  width: auto;
}

.quiz-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.quiz-row:hover {
  background-color: #f5f5f5;
}

.quiz-row .quiz-name a {
  color: #000;
  text-decoration: none;
}

.thumbnail {
  width: 50px;
  height: 50px;
}

.col button {
  padding: 2px 8px;
}

.quiz-list-button-row {
  text-align: left;
}

.resume-quiz-button {
  margin-left: 10px;
}

.header {
  /* ここにヘッダーのスタイルを適用 */
  margin: 10px;
}